<template>
  <el-dialog
    :visible.sync="toVerifyShow"
    width="358px"
    :before-close="handleClose"
    top="10%"
    :email="email"
    v-move-outside
  >
    <div class="dialog_body">
      <div class="size-icon-message">
        <img src="https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/toVerify.png" />
      </div>
      <div class="verify_body">
        <p class="p1">{{ type == 'password' ? $t('Verification information sent') : $t('xiaoxie.Please verfify your email')}}</p>
        <p class="p2">{{$t('xiaoxie.Confirmation email has been sent to')}}:</p>
        <p class="p3_email">
          <u>{{ email }}</u>
          <!-- <u>1395462201@qq.com</u> -->
        </p>
        <p class="p4_1" v-if="type != 'password'">
          {{$t('xiaoxie.Please click on the link in that email to complete your signup')}}
        </p>
        <p class="p4_2">
          {{$t('xiaoxie.check your spam folder')}}
        </p>
        <el-button :loading="loadingButton" @click="() => this.$emit('resend')">
          {{ 0 >= timing ? $t('xiaoxie.Resend email') : `${timing} s` }}
        </el-button>
        <div class="verify_body_bottom">
          <p class="p6_help">{{$t('xiaoxie.Need help')}}?</p>
          <p class="p6_contact">{{$t('xiaoxie.Contact us')}}</p>
        </div>
      </div>
    </div>
    <!-- <addPeople
      :addPeopleShow.sync="addPeopleShow"
      @handleClose="addPeopleClose"
    /> -->
  </el-dialog>
</template>

<script>
//import addPeople from "@/components/addPeople/addPeople";
export default {
  name: 'toVerify',
  components: {
    // addPeople,
  },
  data() {
    return {
      imageUrl: '',
      form: {},
      addPeopleShow: false,
    };
  },
  props: ['toVerifyShow', 'email', 'timing', 'loadingButton', 'type'],
  methods: {
    handleClose() {
      this.$emit('handleClose');
    },
    addPeopleClose() {
      this.addPeopleShow = false;
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
  },
};
</script>

<style lang="less" scoped>

/deep/ .el-dialog__header {
  // display: none;
}

/deep/ .el-button {
  font-size: 12px;
  background-color: #142746;
  color: rgb(255, 250, 250);
  margin-top: 30px;
}

.size-icon-message {
  margin: 16px 0;
  img {
    width: 64px;
  }
}

.dialog_body {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: overlay;
}

.verify_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .p1 {
    font-size: 20px;
    font-weight: 700;
    color: #122545;
    margin-bottom: 32px;
  }

  .p2 {
    font-size: 12px;
    font-weight: 500;
    color: #637381;
  }

  .p3_email {
    font-size: 16px;
    font-weight: 700;
    color: #076F49;
    cursor: pointer;
    margin-bottom: 10px;
  }

  .p4_1 {
    font-size: 14px;
    font-weight: 500;
    color: #637381;
    margin-bottom: 5px;
  }

  .p4_2 {
    font-size: 12px;
    font-weight: 500;
    color: #637381;
  }

  .verify_body_bottom {
    font-size: 12px;
    font-weight: 500;
    color: #637381;
    margin: 10px 0 0 0;
    .p6_help {
      display: inline;
    }
    .p6_contact {
      display: inline;
      font-weight: 500;
      color: #076F49;
      margin-left: 10px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>
