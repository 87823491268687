var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isAlive)?_c('div',{staticClass:"loginBox"},[_c('div',{staticClass:"leftBox",style:("\n    background-image: url(https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/login/%E5%91%A8%E4%B8%80.jpg);\n  ")},[_vm._m(0),_c('div',{staticClass:"mask"})]),_c('div',{staticClass:"rightBox"},[_c('div',{staticClass:"center_box"},[_c('h2',[_vm._v(_vm._s(_vm.$t('login.Retrieve password')))]),_c('div',{staticClass:"inputBox"},[_c('div',{staticClass:"felxInput"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ruleForm.email),expression:"ruleForm.email"},{name:"focus",rawName:"v-focus"},{name:"validate",rawName:"v-validate",value:(_vm.rules.email),expression:"rules.email"}],attrs:{"type":"text","maxlength":50,"autocomplete":"off"},domProps:{"value":(_vm.ruleForm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ruleForm, "email", $event.target.value)}}})]),_c('span',[_vm._v(_vm._s(_vm.$t("registerLoging.Email")))])]),_c('div',{staticClass:"inputBox"},[_c('div',{staticClass:"felxInput"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ruleForm.auth),expression:"ruleForm.auth"},{name:"focus",rawName:"v-focus"},{name:"required",rawName:"v-required",value:({
              message: _vm.$t('login.authCode'),
              errorMessage: _vm.$t('login.Pleaseinputaauthcode')
            }),expression:"{\n              message: $t('login.authCode'),\n              errorMessage: $t('login.Pleaseinputaauthcode')\n            }"}],attrs:{"type":"text","autocomplete":"new-text"},domProps:{"value":(_vm.ruleForm.auth)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ruleForm, "auth", $event.target.value)}}}),_c('div',{staticClass:"getCode",on:{"click":_vm.sendEmailGetCode}},[_vm._v(" "+_vm._s(_vm.timing == -1 ? _vm.$t('bind.getCode') : (_vm.timing + "s"))+" ")])]),_c('span',[_vm._v(_vm._s(_vm.$t("login.authCode")))])]),_c('el-popover',{ref:"popover_register",attrs:{"width":"210","placement":"left-start","trigger":"manual"},model:{value:(_vm.popoverVisible),callback:function ($$v) {_vm.popoverVisible=$$v},expression:"popoverVisible"}},[_c('div',{staticClass:"password_prompt"},[_c('div',{staticClass:"password_prompt_title"},[_vm._v(" "+_vm._s(_vm.$t("login.Passwordshouldbe"))+"： ")]),_vm._l(([
              {
                text: _vm.$t('login.Atleast'),
                icon: 'error_icon.png',
              },
              {
                text: _vm.$t('login.Mininumoneuppercase'),
                icon: 'the_default.png',
              },
              {
                text: _vm.$t('login.Mininum one special character'),
                icon: 'the_default.png',
              } ]),function(item,index){return _c('div',{key:index,staticClass:"password_prompt_strength",staticStyle:{"margin-bottom":"17px"}},[_c('img',{staticClass:"password_prompt_img",attrs:{"src":("https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/" + (index + 1 <= _vm.verificationLevel
                  ? 'success_icon.png'
                  : item.icon))}}),_c('span',[_vm._v(" "+_vm._s(item.text)+" "),_c('span',{staticStyle:{"color":"#71a3d1"}},[_vm._v(" "+_vm._s(index == 0 ? ("*" + (_vm.$t("login.necessary"))) : "")+" ")])])])})],2),_c('div',{staticClass:"inputBox",attrs:{"slot":"reference"},slot:"reference"},[_c('div',{staticClass:"felxInput"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.ruleForm.password),expression:"ruleForm.password",modifiers:{"trim":true}},{name:"focus",rawName:"v-focus"},{name:"validate",rawName:"v-validate",value:(_vm.rules.password),expression:"rules.password"}],class:_vm.passwordShow ? 'passwordInput' : '',attrs:{"type":"text","maxlength":30},domProps:{"value":(_vm.ruleForm.password)},on:{"blur":[function($event){_vm.popoverVisible = false},function($event){return _vm.$forceUpdate()}],"focus":function($event){_vm.popoverVisible = true},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ruleForm, "password", $event.target.value.trim())}}}),_c('i',{staticClass:"iconfont",class:!_vm.passwordShow ? 'icon-yincang show' : 'icon-xianshi',on:{"click":function($event){_vm.passwordShow = !_vm.passwordShow}}})]),_c('span',[_vm._v(_vm._s(_vm.$t("login.Password")))])])]),_c('div',{staticClass:"safetyStrength"},[_c('div',{staticClass:"lines"},_vm._l((3),function(item,index){return _c('p',{key:index,staticClass:"lineTip"})}),0),_c('p',{attrs:{"id":"safetyStrength_tips"}},[_c('span',[_vm._v(_vm._s(_vm.$t('login.Password strength'))+"：")]),_c('span',{staticClass:"passTips"},[_vm._v(_vm._s(_vm.$t('login.Tooweak')))])])]),_c('div',{staticClass:"inputBox"},[_c('div',{staticClass:"felxInput"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.ruleForm.affirmPassword),expression:"ruleForm.affirmPassword",modifiers:{"trim":true}},{name:"focus",rawName:"v-focus"},{name:"required",rawName:"v-required",value:({
              message: 'registerLoging.ConfirmPassword',
              errorMessage: 'login.Pleaseinputapassword'
            }),expression:"{\n              message: 'registerLoging.ConfirmPassword',\n              errorMessage: 'login.Pleaseinputapassword'\n            }"}],class:_vm.passwordShow2 ? 'passwordInput' : '',attrs:{"type":"text","maxlength":30},domProps:{"value":(_vm.ruleForm.affirmPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ruleForm, "affirmPassword", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('i',{staticClass:"iconfont",class:!_vm.passwordShow2 ? 'icon-yincang show' : 'icon-xianshi',on:{"click":function($event){_vm.passwordShow2 = !_vm.passwordShow2}}})]),_c('span',[_vm._v(_vm._s(_vm.$t("registerLoging.ConfirmPassword")))])]),_c('el-button',{staticClass:"buttonBox",staticStyle:{"margin-top":"70px"},attrs:{"type":"primary","loading":_vm.loadingGetStart,"disabled":_vm.submitDisabled},on:{"click":function($event){return _vm.submitForm('ruleForm')}}},[_vm._v(_vm._s(_vm.$t("Confirm modification")))])],1),_c('div',{staticClass:"goLoginBox",on:{"click":_vm.toLogin}},[_c('i',{staticClass:"el-icon-arrow-left"}),_vm._v(" "+_vm._s(_vm.$t('el.pageHeader.title'))+" ")]),_c('div',{staticClass:"translateButton"},[_c('el-tabs',{staticClass:"special_tabs",on:{"tab-click":_vm.translateChange},model:{value:(_vm.translate_type),callback:function ($$v) {_vm.translate_type=$$v},expression:"translate_type"}},[_c('el-tab-pane',{attrs:{"label":"中","name":"zh"}}),_c('el-tab-pane',{attrs:{"label":"En","name":"en"}})],1)],1)]),_c('toVerify',{attrs:{"type":"password","toVerifyShow":_vm.toVerifyShow,"loadingButton":_vm.loadingButton,"email":_vm.ruleForm.email,"timing":_vm.timing},on:{"update:toVerifyShow":function($event){_vm.toVerifyShow=$event},"update:to-verify-show":function($event){_vm.toVerifyShow=$event},"handleClose":_vm.toVerifyClose,"resend":_vm.sendEmailGetCode}})],1):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text"},[_c('h1',[_vm._v("Echola System")]),_c('p',[_vm._v(" The logistics service platform is an online logistics public information service platform established by combining e-commerce mode, Internet technology, logistics technology and other information technologies.")])])}]

export { render, staticRenderFns }